import React from 'react'
import PageTitle from '../components/PageTitle/index'
import CopyBox from '../components/CopyBox'
import ContactForm from '../components/ContactForm'
import Layout from '../components/layout'
import {Link} from 'gatsby'

import EmploymentApp from '../assets/misc/MagnoliasEmploymentApp.pdf'

const ContactCopy = [
  <span key={1}>
    <strong>Note: </strong> If you are reaching out to inquire about employment
    opportunities, please fill out{' '}
    <a href={EmploymentApp} target="_blank" rel="noopener">
      an application
    </a>
  </span>,
  <span key={2}>
    {' '}
    and mail it to us directly at{' '}
    <a href="mailto:magnolias@magnoliasmill.com">magnolias@magnoliasmill.com</a>
    .
  </span>,
  <p>For reservations, please <Link to={'/reservations'}>visit our reservations page</Link> or call us at at <a href="tel:5403389800">(540) 338–9800</a>. </p>
]

const ContactPage = ({ data }) => (
  <Layout>
    <PageTitle header="Contact Us" />
    <CopyBox copy={ContactCopy}>
      <ContactForm />
    </CopyBox>
  </Layout>
)

export default ContactPage
