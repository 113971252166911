import { Component } from 'react'
import {
  buttonStyles,
  buttonHoverStyles,
  plantinBoldItalic,
} from '../../styles'
import TextInput from '../Forms/TextInput'
import TextArea from '../Forms/TextArea'
import { postContactMessage } from '../../utils/api'
import SimpleReactValidator from 'simple-react-validator'
import styled from '@emotion/styled'

const ContactForm = styled.form`
  padding: 2rem 0;
  @media screen and (min-width: 720px) {
    padding: 0;
  }
`

const FormItem = styled.div`
  margin-bottom: 1rem;
`

const ThankYouMessage = styled.p`
  text-align: center;
  font-family: ${plantinBoldItalic};
  font-size: 3.6rem;
`

const options = [
  { name: 'General Inquiries', value: 'general' },
  { name: 'Catering', value: 'catering' },
  { name: 'The Kitchen', value: 'kitchen' },
  { name: 'Employment', value: 'employment' },
]

const Button = styled.button`
  ${buttonStyles}
  ${buttonHoverStyles}
`

class ContactFormComponent extends Component {
  constructor() {
    super()
    this.state = {
      message: {
        name: '',
        topic: options[0].value,
        email: '',
        body: '',
        subject: '',
      },
      submitSuccess: false,
    }
    this.validator = new SimpleReactValidator()
  }

  handleInputChange = event => {
    const { name, value } = event.target
    this.setState({
      ...this.state,
      message: { ...this.state.message, [name]: value },
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    if (!this.validator.allValid()) {
      this.validator.showMessages()
      this.forceUpdate()
      return false
    }
    postContactMessage(this.state.message).then(res =>
      this.setState({ ...this.state, submitSuccess: true })
    )
  }

  recaptchaCallback = () => {
    console.log('hello')
  }

  renderForm = (subject, topic, email, body, name) => (
    <ContactForm onSubmit={this.handleSubmit}>
      {/* <FormItem>
        <Select
          name='topic'
          value={topic}
          optionList={options}
          stateFunction={this.handleInputChange}
          label='Department' />
      </FormItem> */}
      <FormItem>
        {this.validator.message(
          'subject',
          this.state.message.subject,
          'required|min:2|max:120'
        )}
        <TextInput
          label="*Subject"
          placeholder="Our Dinner on Friday Evening"
          name="subject"
          value={subject}
          stateFunction={this.handleInputChange}
        />
      </FormItem>
      <FormItem>
        {this.validator.message(
          'name',
          this.state.message.name,
          'required|min:2|max:120'
        )}
        <TextInput
          label="*Your Name"
          name="name"
          value={name}
          stateFunction={this.handleInputChange}
        />
      </FormItem>
      <FormItem>
        {this.validator.message(
          'email',
          this.state.message.email,
          'required|email'
        )}
        <TextInput
          label="*Email"
          placeholder="jane@magnoliasmill.com"
          name="email"
          value={email}
          stateFunction={this.handleInputChange}
        />
      </FormItem>
      <FormItem>
        {this.validator.message(
          'message',
          this.state.message.body,
          'required|min:2|max:500'
        )}
        <TextArea
          label="Your Message"
          name="body"
          placeholder="Dear Magnolias..."
          value={body}
          stateFunction={this.handleInputChange}
        />
      </FormItem>
      {/* <Recaptcha
      sitekey='6Lcw8mIUAAAAALVkg7gequTvkDxn5-XP1DMrORGX'
      render='explicit'
      theme='dark'
      onloadCallback={this.recaptchaCallback}/> */}
      <Button type="submit">Send</Button>
    </ContactForm>
  )

  render() {
    const { subject, topic, email, body, name } = this.state.message

    if (!this.state.submitSuccess) {
      return this.renderForm(subject, topic, email, body, name)
    } else {
      return <ThankYouMessage> Thank you! </ThankYouMessage>
    }
  }
}

export default ContactFormComponent
